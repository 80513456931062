<template>
  <b-card>
    <div
      class="mt-4 mb-2"
    >
      <div
        v-if="request && request.client"
      >
        <b-row>
          <b-col
            md="2"
          >
            <strong>
              RUC del cliente
            </strong>
          </b-col>
          <b-col
            md="2"
          >
            <p>
              {{ request.client.ruc ? request.client.ruc : '-' }}
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="2"
          >
            <strong>
              Razón social
            </strong>
          </b-col>
          <b-col
            md="2"
          >
            <p>
              {{ request.client.businessName ? request.client.businessName : '-' }}
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="2"
          >
            <strong>
              Fecha de envío
            </strong>
          </b-col>
          <b-col
            md="2"
          >
            <p>
              {{ request.sendingDate ? request.sendingDate.split('T')[0] : '-' }}
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="2"
          >
            <strong>
              Importe total
            </strong>
          </b-col>
          <b-col
            md="2"
          >
            <p>
              {{ request.totalAmount ? request.totalAmount : '-' }}
            </p>
          </b-col>
        </b-row>
      </div>
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <div
          slot="emptystate"
          class="text-center my-5"
        >
          <h3
            v-if="receipts.length>0"
          >
            Su búsqueda no coincide
          </h3>
          <b-overlay
            id="overlay-background"
            :show="show"
            no-wrap
            rounded="lg"
            variant="bg-light"
            opacity="1.00"
            no-fade
            class="mt-5"
          />
          <h3
            v-if="!show && receipts.length==0"
          >
            Aún no han solicitado financiamiento
          </h3>
        </div>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: receiptStatus -->
          <span v-if="props.column.field === 'receiptStatus'">
            <b-badge :variant="statusVariant(props.row.receiptStatus)">
              {{ getStatus(props.row.receiptStatus) }}
            </b-badge>
          </span>
          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-button
                variant="primary"
                :to="{ name:'Admin-receipt-detail', params:{ id: props.row._id } }"
                block
              >
                Ver detalle
              </b-button>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Mostrando grupos de
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap "> de {{ props.total }} registro(s) </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </b-card>
</template>

<script>
/* eslint-disable global-require */
import {
  BBadge, BPagination, BFormSelect, BButton, BCard, BOverlay, BRow, BCol,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'

export default {
  components: {
    VueGoodTable,
    BRow,
    BCol,
    BOverlay,
    BBadge,
    BPagination,
    BFormSelect,
    BButton,
    BCard,
  },
  data() {
    return {
      show: true,
      userEmail: JSON.parse(localStorage.getItem('userData')).email,
      pageLength: 3,
      dir: false,
      columns: [
        {
          label: 'Pagador',
          field: 'payer.businessName',
          filterOptions: {
            enabled: true,
            placeholder: 'Pagador',
          },
        },
        {
          label: 'Fecha de registro',
          field: 'createdAt',
          tdClass: 'text-center',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          formatFn: this.dateFormat,
          filterOptions: {
            enabled: true,
            placeholder: 'Fecha de R.',
          },
        },
        {
          label: 'Tipo de Documento',
          field: 'documentType',
          filterOptions: {
            enabled: true,
            placeholder: 'Tipo de doc.',
          },
        },
        {
          label: 'N° del Documento',
          field: 'documentNumber',
          filterOptions: {
            enabled: true,
            placeholder: 'N° del doc.',
          },
        },
        {
          label: 'Importe de pago',
          field: 'payingAmount',
          type: 'number',
          formatFn: this.moneyFormat,
          filterOptions: {
            enabled: true,
            placeholder: 'Buscar importe',
          },
          tdClass: 'text-right',
        },
        {
          label: 'Fecha de pago',
          field: 'payingDate',
          tdClass: 'text-center',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          formatFn: this.dateFormat,
          filterOptions: {
            enabled: true,
            placeholder: 'Buscar fecha p.',
          },
        },
        {
          label: 'Plazo',
          field: 'payingTerm',
          filterOptions: {
            enabled: true,
            placeholder: 'Buscar plazo',
          },
        },
        {
          label: 'Estatus',
          field: 'receiptStatus',
          filterOptions: {
            enabled: true,
            placeholder: 'Buscar estatus',
            filterDropdownItems: [
              { value: 'notApproved', text: 'Por Aprobar' },
              { value: 'approved', text: 'Aprobado' },
              { value: 'rejected', text: 'Denegado' },
            ],
          },
        },
        {
          label: 'Detalle',
          field: 'action',
        },
      ],
      rows: [],
      receipts: [],
      request: '',
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        current      : 'light-primary',
        approved : 'light-success',
        rejected     : 'light-danger',
        notApproved     : 'light-warning',
        partiallyApproved      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    const requestId = this.$route.params.id
    useJwt.getAllRecipts({
      email: this.userEmail,
      requestId,
    }).then(res => {
      const value = res.data.request
      for (let i = 0; i < value.receipts.length; i += 1) {
        value.receipts[i].createdAt = this.normalizeDate(value.receipts[i].createdAt)
      }
      this.rows = value && value.receipts ? value.receipts : []
      this.receipts = this.rows.length > 0 ? this.rows : []
      this.request = res.data.request ? res.data.request : ''
      this.show = false
    })
  },
  methods: {
    dateFormat(value) {
      return value ? value.split('-').reverse().join('/') : '-'
    },
    moneyFormat(money) {
      return !Number.isNaN(Number.parseFloat(money)) ? Number.parseFloat(money).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '-'
    },
    normalizeDate(date) {
      const newDate = new Date(date)
      newDate.setTime(newDate.getTime() + 5 * 60 * 60 * 1000)
      const dateArr = []
      dateArr.push(newDate.getFullYear().toString(), (newDate.getMonth() + 1).toString().padStart(2, '0'), newDate.getDate().toString().padStart(2, '0'))
      return dateArr.join('-')
    },
    getStatus(receiptStatus) {
      let label = ''
      switch (receiptStatus) {
        case 'rejected':
          label = 'Rechazado'
          break
        case 'notApproved':
          label = 'Pendiente'
          break
        case 'approved':
          label = 'Aceptado'
          break
        default:
          break
      }
      return label
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep .card .card-body {
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
